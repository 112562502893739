import React, { useEffect } from "react";
import { ReportCSV } from "../reducers/AllApis";
import { useDispatch, useSelector } from "react-redux";
import numeral from "numeral";
import { PercentColor } from "../utils/utils";

const Reports = () => {
  const dispatch = useDispatch();
  const { ReportData } = useSelector((state) => state.allapi);

  useEffect(() => {
    dispatch(ReportCSV());
  }, []);



  const rowData = [
    {
      percentVar: -20,
    },
    {
      percentVar: 20,
    },
    {
      percentVar: -20,
    },
  ];

  return (
    <div className="">
      {/* <div className="CardHead">
      Reports
    </div>
    <div  className="LabourInsights">
    <table>
      <thead>
        <tr>
          <th>% AC GP</th>
          <th>Job number</th>
          <th>Site adress</th>
          <th>Client</th>
          <th>Est Rev $</th>
          <th>Actual Margin $</th>
          <th>Est. Margin $</th>
          <th>Est. Budget $</th>
          <th>Actual $ CTC</th>
          <th>Budget (Over / U)</th>
        </tr>
      </thead>
      <tbody>
        {ReportData &&
          ReportData.map((x, i) => {
            const GrossPer = (x.total_est_margin / x.total_revenue) * 100

              return (
              <tr key={i}>
                <td>{GrossPer ? `${GrossPer.toFixed(0)}%` : 0}</td>
                <td>{x.job_id ? x.job_id : 0}</td>
                <td>{x.site_name ? x.site_name : "-"}</td>
                <td>{x.client_firm ? x.client_firm : "-"}</td>
                <td>{x.total_revenue ? numeral(x.total_revenue).format(0,0)  : 0}</td>
                <td>{x.total_actual_margin ? numeral(x.total_actual_margin).format(0,0) : 0}</td>
                <td>{x.total_est_margin ?  numeral(x.total_est_margin).format(0,0) : 0}</td>
                <td>{x.total_est_budget ?  numeral(x.total_est_budget).format(0,0) : 0}</td>
                <td>{x.total_actual_ctc ?  numeral(x.total_actual_ctc).format(0,0) : 0}</td>
                <td>{x.total_budget_over_under ? `${x.total_budget_over_under}%` : 0}</td>
        
                
              </tr>
            );
          })}
      </tbody>
    </table>
    </div> */}
      <div className="NewUser NewJob Reports">
        <div className="JobTable">
          <table>
            <thead>
              <tr>
                <th>Project Manager</th>
                <th>% Progress</th>

                <th>WIP</th>
                <th>Site</th>
                <th>Labour H Target</th>
                <th>Labour H Actual</th>
                <th>% Var</th>
                <th>Labour H Target M</th>
                <th>Labour H Actual M</th>
                <th>% Var</th>
                <th>Client</th>
                <th>Job</th>
                {/* <th>Project Tag</th>
                <th>Product Tag</th> */}
              </tr>
            </thead>
            <tbody>
              {ReportData.length > 0 ? (
                ReportData.map((x, i) => (
                  <tr key={i}>
                    <td style={{ minWidth: "200px" }}>
                      {/* <svg
                        style={{ minWidth: "36px" }}
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M25.875 14.625H23.625V18H20.25V20.25H23.625V23.625H25.875V20.25H29.25V18H25.875V14.625ZM9 14.625C9 17.19 10.935 19.125 13.5 19.125C16.065 19.125 18 17.19 18 14.625C18 12.06 16.065 10.125 13.5 10.125C10.935 10.125 9 12.06 9 14.625ZM15.75 14.625C15.75 15.9503 14.8252 16.875 13.5 16.875C12.1747 16.875 11.25 15.9503 11.25 14.625C11.25 13.2998 12.1747 12.375 13.5 12.375C14.8252 12.375 15.75 13.2998 15.75 14.625ZM9 25.875C9 24.0142 10.5143 22.5 12.375 22.5H14.625C16.4858 22.5 18 24.0142 18 25.875V27H20.25V25.875C20.25 22.7734 17.7266 20.25 14.625 20.25H12.375C9.27338 20.25 6.75 22.7734 6.75 25.875V27H9V25.875Z"
                          fill="currentColor"
                        />
                        <circle
                          cx="18"
                          cy="18"
                          r="17.5"
                          stroke="currentColor"
                          strokeLinecap="square"
                          strokeDasharray="1 5"
                        />
                      </svg> */}
                      {x.project_manager ? x.project_manager : "-"}
                    </td>
                    <td>{x.percent_complete ? `${(x.percent_complete).toFixed(1)}%` : "-"}</td>
                    <td>{x.wip ? numeral(x.wip).format("$0,0") : "-"}</td>
                    <td  style={{ minWidth: "200px" }}>{x.site.site_name ? x.site.site_name : "-"}</td>

                    <td>
                      {x.target_labour_hour
                        ? `${x.target_labour_hour.toFixed(0)}H`
                        : "-"}
                    </td>
                    <td>
                      {x.actual_labour_hour
                        ? `${x.actual_labour_hour.toFixed(0)}H`
                        : "-"}
                    </td>
                    <td>{x.var_total ? PercentColor(x.var_total) : "-"}</td>
                    <td>
                      {x.target_labour_hour_month
                        ? `${x.target_labour_hour_month.toFixed(0)}H`
                        : "-"}
                    </td>
                    <td>
                      {x.actual_labour_hour_month
                        ? `${x.actual_labour_hour_month.toFixed(0)}H`
                        : "-"}
                    </td>
                    <td>
                      {x.var_total_month
                        ?  PercentColor(x.var_total_month)
                        : "-"}
                    </td>
                    <td  style={{ minWidth: "200px" }}>{x.client ? x.client : "-"}</td>
                    <td>{x.job_id ? x.job_id : "-"}</td>
                 
                  </tr>
                ))
              ) : (
                <div className="ItemHead">No Data</div>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Reports;
