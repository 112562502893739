import React from 'react'
import Jobs from '../pages/Jobs';
import Reports from '../pages/Reports';
import SignIn from '../pages/Authentication/SignIn';
import JobDetails from '../pages/Details1';
import Settings from '../pages/Settings';
import Details from './Jobs/Details';
import Jobs1 from '../pages/Jobs1';
import Users from '../pages/Users';
import Test1 from '../pages/Test1';
import Test from '../Test';
import JobNew from '../pages/JobNew';

const coreRoutes = [
    {
      path: '/jobs',
      title: 'All Jobs',
      component:JobNew ,
    },
    {
      path: '/users',
      title: 'Users',
      component: Users,
    },
    {
        path: '/reports',
        title: 'Reports',
        component: Reports,
      },
      // {
      //   path: '/',
      //   title: 'SignIn',
      //   component: SignIn,
      // },
      {
        path: '/jobs/:value',
        title: 'Details',
        component: JobDetails,
      },
      {
        path: '/settings',
        title: 'Settings',
        component: Settings,
      },
      {
        path: '/test',
        title: 'Details',
        component: Test,
      },
      {
        path: '/jobs1',
        title: 'Details',
        component: JobNew,
      },
    
  ];

  
  const routes = [...coreRoutes];
  export default routes;