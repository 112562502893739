import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ListExcel, ResetTable } from "./reducers/AllApis";
import { DateForm } from "./utils/utils";

const Test = () => {
  
  const handleChangeSome = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  return (
    <div>
      <div  style={{height: "1000px"}}>hi</div>
     
      <button onClick={handleChangeSome}>clicl</button>



      
    </div>
  );
};


export default Test;
