import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserList } from "../reducers/AllApis";
import { formatMonth, TimeForm } from "../utils/utils";
import Manager from "../assets/images/ManagerPlaceholder.svg";


const Users = () => {
  const dispatch = useDispatch();

  const { UserListData } = useSelector((state) => state.allapi);

  useEffect(() => {
    dispatch(UserList());
  }, []);
  return (
    <>
      <div className="NewUser NewJob">
        <div className="JobTable">
          <table>
            <thead>
              <tr>
              <th>Name</th>
                <th>Email</th>
            
                <th>Active</th>
                <th>Contact</th>
                <th>Last Login</th>
                <th>Date Joined</th>
          
                <th>Country</th>
                <th>City</th>
              </tr>
            </thead>
            <tbody>
              {UserListData && UserListData.length > 0 ? (
                UserListData.map((x, i) => {
                  return (
                    <tr key={i}>
                    
                      <td className="Profile">{x.profile ? <img src={x.profile}/> : <img src={Manager}/>}{x.fullname ? x.fullname : "-"}</td>
                      <td>{x.email ? x.email : "-"}</td>
                      <td>{x.is_active ?  <div className="StatTab wip">Active</div> : <div className="StatTab wipNot">Disabled</div>}</td>
                      <td>{x.contact ? x.contact : "-"}</td>
                      <td>{x.last_login ? TimeForm(x.last_login) : "-"}</td>
                      <td>
                        {x.date_joined ? formatMonth(x.date_joined) : "-"}
                      </td>

                    
                      <td>{x.country ? x.country : "-"}</td>
                      <td>{x.city ? x.city : "-"}</td>
                    </tr>
                  );
                })
              ) : (
                <div className="ItemHead">No Data</div>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
export default Users;
