import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ActiveJobPopup,
  ActivePopup,
  AddJob,
  AddTags,
  InactiveJobPopup,
  ListExcel,
  ListExcelSearch,
  ResetSuccessUpdate,
  Tags,
  UpdateJobtype,
} from "../reducers/AllApis";
import { CompBar, formatMonth, StatFormat } from "../utils/utils";
import { useNavigate } from "react-router-dom";
import numeral from "numeral";
import Filter from "./../components/Jobs/Filters";
import DateRangeFilter from "../components/Jobs/DateRangeFilter";
import AddJobs from "../components/Jobs/AddJobs";
import AddJobsNew from "../components/Jobs/AddJobsNew";

const JobNew = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(
    Number(sessionStorage.getItem("currentPage")) || 1
  );
  const [searchTerm, setSearchTerm] = useState(
    sessionStorage.getItem("searchTerm") || ""
  );
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [filters, setFilters] = useState({
    status: sessionStorage.getItem("status") || "",
    dateRange: sessionStorage.getItem("dateRange") || "",
    tags: JSON.parse(sessionStorage.getItem("tags")) || "",
    projectManager: sessionStorage.getItem("projectManager") || "",
    start_date: sessionStorage.getItem("Custom start date") || "",
    end_date: sessionStorage.getItem("Custom end date") || "",
  });

  const hasEmptyValue = Object.values(filters).some((value) => value !== "");

  const {
    Listdata,
    ListCount,
    ListSearchCount,
    ListSearchdata,
    TagsData,
    ProjectManagersData,
    loading,
    OpenPopup,
    AddTagsSuccess,
    OpenJobPopup
  } = useSelector((state) => state.allapi);
  const JobPopupRef = useRef();
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [StartDate, setStartDate] = useState("");
  const [EndDate, setEndDate] = useState("");

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 1000);
    return () => clearTimeout(timer);
  }, [searchTerm]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      dispatch(ListExcelSearch({ search: debouncedSearchTerm }));
      sessionStorage.setItem("searchTerm", debouncedSearchTerm);
      sessionStorage.removeItem("currentPage");
      setCurrentPage(1);
    } else if (
      filters.status === "" &&
      filters.projectManager === "" &&
      filters.tags === "" &&
      filters.dateRange === "" &&
      filters.start_date === "" &&
      filters.end_date === ""
    ) {
      sessionStorage.removeItem("searchTerm");
      dispatch(ListExcel({ page: currentPage }));
    }
  }, [debouncedSearchTerm, currentPage, dispatch, filters]);

  useEffect(() => {
    if (TagsData && ProjectManagersData) {
      setIsDataLoaded(true);
    }
  }, [TagsData, ProjectManagersData]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    sessionStorage.setItem("currentPage", page);
    if (!debouncedSearchTerm) {
      dispatch(ListExcel({ page }));
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleNav = (data) => {
    navigate(`/jobs/${data.job_id}`, {
      state: { data: data.job_id },
    });
  };

  const ListDataMain = debouncedSearchTerm ? ListSearchdata : Listdata;
  const ListCountMain = debouncedSearchTerm ? ListSearchCount : ListCount || 0;

  const totalPages = Math.ceil(ListCountMain / 50);
  const handleFilterChange = (name, value) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters, [name]: value };

      if (name === "tags") {
        sessionStorage.setItem(name, JSON.stringify(value));
      } else {
        sessionStorage.setItem(name, value);
      }

      return newFilters;
    });
  };

  useEffect(() => {
    const params = new URLSearchParams();

    filters &&
      filters.tags &&
      filters.tags.forEach((tag) => {
        params.append("tags", tag);
      });

    const data = {
      project_manager_name: filters.projectManager,
      status: filters.status,
      filter_type: filters.dateRange,
      custom_start_date: filters.start_date,
      custom_end_date: filters.end_date,
    };

    Object.entries(data).forEach(([key, value]) => {
      if (value) {
        params.append(key, value);
      }
    });
    if (
      params.toString() &&
      ((!filters.start_date && !filters.end_date) ||
        (filters.start_date && filters.end_date))
    ) {
      sessionStorage.removeItem("currentPage");
      dispatch(ListExcel(params));
    }
  }, [
    filters.status,
    filters.dateRange,
    filters.tags,
    filters.projectManager,
    filters.start_date,
    filters.end_date,
  ]);

  const handleDatePopup = () => {
    dispatch(ActivePopup());
  };

  const handleSelectData = (data) => {
    sessionStorage.setItem("dateRange", data);

    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters, dateRange: data };
      return newFilters;
    });
  };

  const handleClearFilter = () => {
    sessionStorage.removeItem("status");
    sessionStorage.removeItem("dateRange");
    sessionStorage.removeItem("tags");
    sessionStorage.removeItem("projectManager");
    sessionStorage.removeItem("Custom end date");
    sessionStorage.removeItem("Custom start date");

    setFilters({
      status: "",
      dateRange: "",
      tags: "",
      projectManager: "",
      start_date: "",
      end_date: "",
    });
    setSearchTerm("");
    setDebouncedSearchTerm("");

    sessionStorage.removeItem("currentPage");

    dispatch(ListExcel({ page: currentPage }));
    // dispatch(ListExcelSearch({ search: searchTerm, page: currentPage }));
    // alert("hi")
  };

  const handleAddTags = (data) => {
    const obj = {
      tag: data,
    };
    dispatch(AddTags(obj));
  };

  useEffect(() => {
    if (AddTagsSuccess === true) {
      dispatch(Tags(""));
      dispatch(ResetSuccessUpdate());
    }
  }, [AddTagsSuccess]);

  const handleClearFil = () => {
    sessionStorage.removeItem("dateRange");
    sessionStorage.removeItem("Custom start date");
    sessionStorage.removeItem("Custom end date");
  };

  const handleSelectDataCustom = (data, name) => {
    sessionStorage.setItem(name, data);
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name === "Custom start date" ? "start_date" : "end_date"]: data,
    }));
  };

  const SelectStatus = ({ status, jobid }) => {
    const [Show, setShow] = useState(false);

    const handleSelectData = (data) => {
      const statusobj = {
        ID: jobid,
        status: data,
      };
      if (statusobj.status !== "") {
        // console.log(statusobj);

        dispatch(UpdateJobtype(statusobj))
      }
    };

  

    return (
      <div className="Filters">
        <div className="StatusItem" onClick={() => setShow(!Show)}>
          {status}
        </div>
        {Show && (
          <div className="FilterOptions">
             <div className="Options" onClick={() => handleSelectData("")}>
              select
            </div>
            <div className="Options" onClick={() => handleSelectData("wip")}>
              wip
            </div>
            <div className="Options" onClick={() => handleSelectData("done")}>
              done
            </div>
          </div>
        )}
      </div>
    );
  };

  const handleJobPopup = () => {
    dispatch(ActiveJobPopup());
  };

  const JobDataPopupRef = useRef();



  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        JobDataPopupRef.current &&
        !JobDataPopupRef.current.contains(event.target)
      ) {
        dispatch(InactiveJobPopup());
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dispatch]);

  return (
    <div className="NewJob">
      <div className="FilterTable">
        <div className="FilterItemDiv">
          <Filter
            name="Status"
            options={["TBC", "WIP*", "WIP", "DONE"]}
            value={filters.status}
            onChange={(value) => handleFilterChange("status", value)}
          />
          <Filter
            name="Date Range"
            options={["Custom"]}
            value={filters.dateRange}
            isDateFilter={true}
            handleSelectData={handleSelectData}
            onChange={(value) => handleFilterChange("dateRange", value)}
            handleOpenDateRange={handleDatePopup}
          />
          <Filter
            name="Tag Selector"
            options={TagsData}
            value={filters.tags}
            onChange={(value) => handleFilterChange("tags", value)}
            isTags={true}
            handleAddTags={handleAddTags}
          />
          <Filter
            name="Project Manager"
            options={ProjectManagersData}
            value={filters.projectManager}
            onChange={(value) => handleFilterChange("projectManager", value)}
            classname="ProjectManager"
          />
          {filters.dateRange ||
          filters.status ||
          filters.projectManager ||
          filters.end_date ||
          filters.start_date ||
          filters.tags ? (
            <div className="Filters ClearFilter" onClick={handleClearFilter}>
              Clear <span style={{ marginLeft: "10px" }}>&#10005;</span>
            </div>
          ) : (
            ""
          )}
          <input
            className="SearchJobs"
            type="text"
            placeholder="Search jobs..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
         {/* <div className="AddJobs" onClick={handleJobPopup}>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.3335 8.66665H3.3335V7.33331H7.3335V3.33331H8.66683V7.33331H12.6668V8.66665H8.66683V12.6666H7.3335V8.66665Z"
              fill="#0035F0"
            />
          </svg>

          <div>Create Job</div>
        </div>
     
        {OpenJobPopup && (
          <div className="AddJobForm">
            <AddJobs
              ref={JobDataPopupRef}
            />
            <AddJobsNew ref={JobDataPopupRef}/>
          </div>
        )} */}
        {OpenPopup && (
          <div className="AddJobForm">
            <DateRangeFilter
              Data={filters.dateRange}
              ref={JobPopupRef}
              handleSelectData={handleSelectData}
              handleSelectDataCustom={handleSelectDataCustom}
              handleClearFil={handleClearFil}
              Start_Date={filters.start_date}
              End_Date={filters.end_date}
            />
          </div>
        )}
      </div>

      <div className="JobTable">
        <table>
          <thead>
            <tr>
              <th>Project manager</th>
              <th>Job no.</th>
              <th>Start</th>
              <th>End</th>
              <th>Status</th>
              <th>Revenue</th>
              <th>WIP</th>
              <th className="SiteName">Client Name</th>
              <th className="SiteName">Site Name</th>
              <th>% Comp</th>
            </tr>
          </thead>
          <tbody>
            {ListDataMain.length > 0 ? (
              ListDataMain.map((x, i) => (
                <tr
                  key={i}
                  onClick={() => handleNav(x)}
                >
                  <td>
                    {/* <svg
                      width="36"
                      height="36"
                      viewBox="0 0 36 36"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M25.875 14.625H23.625V18H20.25V20.25H23.625V23.625H25.875V20.25H29.25V18H25.875V14.625ZM9 14.625C9 17.19 10.935 19.125 13.5 19.125C16.065 19.125 18 17.19 18 14.625C18 12.06 16.065 10.125 13.5 10.125C10.935 10.125 9 12.06 9 14.625ZM15.75 14.625C15.75 15.9503 14.8252 16.875 13.5 16.875C12.1747 16.875 11.25 15.9503 11.25 14.625C11.25 13.2998 12.1747 12.375 13.5 12.375C14.8252 12.375 15.75 13.2998 15.75 14.625ZM9 25.875C9 24.0142 10.5143 22.5 12.375 22.5H14.625C16.4858 22.5 18 24.0142 18 25.875V27H20.25V25.875C20.25 22.7734 17.7266 20.25 14.625 20.25H12.375C9.27338 20.25 6.75 22.7734 6.75 25.875V27H9V25.875Z"
                        fill="currentColor"
                      />
                      <circle
                        cx="18"
                        cy="18"
                        r="17.5"
                        stroke="currentColor"
                        strokeLinecap="square"
                        strokeDasharray="1 5"
                      />
                    </svg> */}

                    {x.project_manager ? x.project_manager : "-"}
                  </td>
                  <td>J{x.job_id || "-"}</td>
                  <td>{x.start_date ? formatMonth(x.start_date) : "-"}</td>
                  <td>{x.end_date ? formatMonth(x.end_date) : "-"}</td>
                  <td>
                    {/* <SelectStatus
                      status={x.status ? x.status.toUpperCase() : "-"}
                      jobid={x.job_id ? x.job_id : ""}
                    /> */}
                    {x.status ? (
                      <StatFormat stat={x.status.toUpperCase()} />
                    ) : (
                      "-"
                    )}
                  </td>

                  <td>{x.revenue ? numeral(x.revenue).format("$0,0") : "-"}</td>
                  <td>{x.wip ? numeral(x.wip).format("$0,0") : "0"}</td>
                  <td>{x.client ? x.client : "-"}</td>

                  <td>{x.site || "-"}</td>
                  <td>
                    {x.percent_complete ? (
                      <CompBar data={x.percent_complete} />
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <div className="ItemHead">No Data</div>
            )}
          </tbody>
        </table>
      </div>
      {!hasEmptyValue && !searchTerm && (
        <div className="JobPagination">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Prev
          </button>
          {[...Array(totalPages)].map((_, index) => {
            const page = index + 1;
            return (
              <button
                key={page}
                onClick={() => handlePageChange(page)}
                style={{
                  margin: "0 5px",
                  backgroundColor: currentPage === page ? "blue" : "white",
                  color: currentPage === page ? "white" : "black",
                  border: "1px solid #888888",
                  padding: "5px 10px",
                }}
              >
                {page}
              </button>
            );
          })}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default JobNew;
