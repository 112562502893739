import React, { useState } from "react";
import ShowPass from "../../assets/images/Show-Pass.png";
import HidePass from "../../assets/images/Hide-Pass.png";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const ResetPass = () => {
  const [ShowOldPassword, setShowOldPassword] = useState(false);
  const [ShowNewPassword, setShowNewPassword] = useState(false);
  const [OldPass, setOldPass] = useState('');
  const [NewPass, setNewPass] = useState('');

  const handlePassSubmit = async (e) => {
    e.preventDefault();

    if (OldPass === '' || NewPass === '') {
      toast.error("Both fields are required!");
      return;
    }

    const formData = new FormData();
    formData.append('old_password', OldPass);
    formData.append('new_password', NewPass);

    const IdToken = localStorage.getItem('IdToken');

    const config = {
      headers: {
        Authorization: `Bearer ${IdToken}`,
      },
    };

    try {
      const res = await axios.put(
        'https://quotech-uat-api.bravadagroup.com.au/api/auth/change-password/',
        formData,
        config,
      );

      if (res.data.detail) {
        toast.success(res.data.detail);
    
        setOldPass('');
        setNewPass('');
      }
    } catch (err) {
      if (err.response && err.response.data.old_password) {
        toast.error(err.response.data.old_password);
      } else {
        toast.error("An error occurred. Please try again.");
      }
    }
  };

  return (
    <div className="ResetPass ProfileEditPage">
      <Toaster/>
      <div className="EditDet">
        <form onSubmit={handlePassSubmit}>
          <div className="FormSec">
            <div className="InputDiv">
              <div className="Label">Old Password</div>
              <input
                type={ShowOldPassword ? "text" : "password"}
                name="old_password"
                placeholder="Enter your old password"
                value={OldPass}
                onChange={(e) => setOldPass(e.target.value)} 
              />
              <span className="settingresetShowPass">
                {/* <img
                  src={ShowOldPassword ? HidePass : ShowPass}
                  alt="Toggle Password Visibility"
                  onClick={() => setShowOldPassword(!ShowOldPassword)}
                  style={{ width: "30px" }}
                /> */}
                <img
                  src={ShowOldPassword ? 
                    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAACiUlEQVR4nO1VOYsUQRid8UJRcEXxBI/QVROvSNFADTzSDUTQzFhDEcZ8V0EDZTJBg52W7nqvupd1FGwRXY9fIJh5sAbemiyCLW/9Wsp2dhnWxWg+KJh59dV7/dVX9apW60UvevG/I8/zhc65YwCGSGYAxjTst7Cjypk1Qe/9LgAtAN8AfAAQAxgEcMbGEABH8pPltLRmxoLOuW0ARkkWAH6QPBFF0dyp8vM8n0fylOVqzag4uhaMomgByQskJwCkJB+QHA5zSA5ozuYHwjnbkYf8FRPiEue0ommargPwmOQbAEesii8ADgQ7cVoVhUNYOe+9P6Q1WuucO2xcY+LuKOqc2wHgLcm7cRyvFJZl2QYj7gsqelEVFlbOZ1m2TJj3fr3+i0uc4pZGVXSvVXY97COAfpGEp5Xk+6qwsKBVi+xj+ktM1YtbGtKaBJMk2QzgM8nLRVHUKx/Up8MSkpC82aHiG8H8Fq2JomhpyFUURR3AFWlJMxS+WBW2qp+RPBf0cIW2LhC9IyzIP0/ySZWnKIq6NH4LG9keAQCuVq+MrhHJdyRXhXgcx2s0QizLstW668654yFuh/SaNKT1xxd577cDGCd5O6yg0WjMAdBWFUmSLK9WUtmJp7q/4c4J13pxS2O66yQrfE3yYKXX90m+JHmy3W4vLueiKFoi4yD5iuS9sLfiKK8TgLVTfXQnA2k55zYKbzab8wGctSsnY3huQ3njsk/lmOAmkre6NpAw0jTdSnIEwHc5l/d+n3qlM5AkyW71UUO+rHaYYew3X9eaEZ3w2kwDwE4Jk/xK8iPJhOSl8pGw35OPhOUM/2UU/xIyET199jLJox9pmGcPmsXO3rPYi170otZl/AQk7awEpS79SwAAAABJRU5ErkJggg==" : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAACB0lEQVR4nN2VO4sUURCFR9cn+ADFcDPBBxqo+Fh0hQ3FRAwNXBb8H5OJgaCGI5qIbnBX+36nBi9mLe7CCqKBsWgiCr5FDGWk9PbSNjPuLjiCFjQ907eqTlWdU92t1n9jZnbCzMaGBiDpCPA6xjg+TJCjDgJMDA0kxjjer5MQwoZut7snxrg3hLDlT4GcAc4Dz4Gvkh4Ac5I+5mcXi6LYtWyAoii2OoikL8CHGOO5lNLa6rzT6ayWNCnpvaRvwHSMcXRJyYGzkl4BxweNqzIz2wm8lXQrczc1MLFXBVyT9EjSdkk3newKxMyODShoSj/NY54Al0III784lWW5TtI9b9V/++WV+d3PKxBgPic5XMWGENa4L7Ax55kBuiGE9T8cer3eCkl3JV1tt9src1W7JT2sF1FxIqkHPG50MQfsy4AjPgkgLQD4H39YtWZm+4HZehJJp2vEzzcAZusAkq57FwsO3k6e44y3mVLa5G07LzVCD5rZqSYnmbs3vieeB7gjyeqqa9WQL/uMM2HmUuxHbF1d7gPcdkVJeirpSlmWq/rFVaOYzIROexce+DsQrz7L9KUv5sDEjeBR4IYvkaR3LkVXS3WeVTZR4+R+Smlba7kG7AAuSHoGfPZXhatL0idJL4qiOLTYnizZQgibY4wHnOxmtYtt/L/3qrdhfhnNbAw4OTSAv27fAShs9Uh8nsyjAAAAAElFTkSuQmCC"}
                  alt="Toggle Password Visibility"
                  onClick={() => setShowOldPassword(!ShowOldPassword)}
                  style={{ width: "30px" }}
                />
              </span>
            </div>

            <div className="InputDiv">
              <div className="Label">New Password</div>
              <input
                type={ShowNewPassword ? "text" : "password"}
                name="new_password"
                placeholder="Enter your new password"
                value={NewPass}
                onChange={(e) => setNewPass(e.target.value)} 
              />
              <span className="settingresetShowPass">
                {/* <img
                  src={ShowNewPassword ? HidePass : ShowPass}
                  alt="Toggle Password Visibility"
                  onClick={() => setShowNewPassword(!ShowNewPassword)}
                  style={{ width: "30px" }}
                /> */}
                <img
                  src={ShowNewPassword ?"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAACiUlEQVR4nO1VOYsUQRid8UJRcEXxBI/QVROvSNFADTzSDUTQzFhDEcZ8V0EDZTJBg52W7nqvupd1FGwRXY9fIJh5sAbemiyCLW/9Wsp2dhnWxWg+KJh59dV7/dVX9apW60UvevG/I8/zhc65YwCGSGYAxjTst7Cjypk1Qe/9LgAtAN8AfAAQAxgEcMbGEABH8pPltLRmxoLOuW0ARkkWAH6QPBFF0dyp8vM8n0fylOVqzag4uhaMomgByQskJwCkJB+QHA5zSA5ozuYHwjnbkYf8FRPiEue0ommargPwmOQbAEesii8ADgQ7cVoVhUNYOe+9P6Q1WuucO2xcY+LuKOqc2wHgLcm7cRyvFJZl2QYj7gsqelEVFlbOZ1m2TJj3fr3+i0uc4pZGVXSvVXY97COAfpGEp5Xk+6qwsKBVi+xj+ktM1YtbGtKaBJMk2QzgM8nLRVHUKx/Up8MSkpC82aHiG8H8Fq2JomhpyFUURR3AFWlJMxS+WBW2qp+RPBf0cIW2LhC9IyzIP0/ySZWnKIq6NH4LG9keAQCuVq+MrhHJdyRXhXgcx2s0QizLstW668654yFuh/SaNKT1xxd577cDGCd5O6yg0WjMAdBWFUmSLK9WUtmJp7q/4c4J13pxS2O66yQrfE3yYKXX90m+JHmy3W4vLueiKFoi4yD5iuS9sLfiKK8TgLVTfXQnA2k55zYKbzab8wGctSsnY3huQ3njsk/lmOAmkre6NpAw0jTdSnIEwHc5l/d+n3qlM5AkyW71UUO+rHaYYew3X9eaEZ3w2kwDwE4Jk/xK8iPJhOSl8pGw35OPhOUM/2UU/xIyET199jLJox9pmGcPmsXO3rPYi170otZl/AQk7awEpS79SwAAAABJRU5ErkJggg==" : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAACB0lEQVR4nN2VO4sUURCFR9cn+ADFcDPBBxqo+Fh0hQ3FRAwNXBb8H5OJgaCGI5qIbnBX+36nBi9mLe7CCqKBsWgiCr5FDGWk9PbSNjPuLjiCFjQ907eqTlWdU92t1n9jZnbCzMaGBiDpCPA6xjg+TJCjDgJMDA0kxjjer5MQwoZut7snxrg3hLDlT4GcAc4Dz4Gvkh4Ac5I+5mcXi6LYtWyAoii2OoikL8CHGOO5lNLa6rzT6ayWNCnpvaRvwHSMcXRJyYGzkl4BxweNqzIz2wm8lXQrczc1MLFXBVyT9EjSdkk3newKxMyODShoSj/NY54Al0III784lWW5TtI9b9V/++WV+d3PKxBgPic5XMWGENa4L7Ax55kBuiGE9T8cer3eCkl3JV1tt9src1W7JT2sF1FxIqkHPG50MQfsy4AjPgkgLQD4H39YtWZm+4HZehJJp2vEzzcAZusAkq57FwsO3k6e44y3mVLa5G07LzVCD5rZqSYnmbs3vieeB7gjyeqqa9WQL/uMM2HmUuxHbF1d7gPcdkVJeirpSlmWq/rFVaOYzIROexce+DsQrz7L9KUv5sDEjeBR4IYvkaR3LkVXS3WeVTZR4+R+Smlba7kG7AAuSHoGfPZXhatL0idJL4qiOLTYnizZQgibY4wHnOxmtYtt/L/3qrdhfhnNbAw4OTSAv27fAShs9Uh8nsyjAAAAAElFTkSuQmCC"}
                  alt="Toggle Password Visibility"
                  onClick={() => setShowNewPassword(!ShowNewPassword)}
                  style={{ width: "30px" }}
                />
              </span>
            </div>
          </div>

          <div className="FormSec justify-end">
            <button type="submit" className="ButtonPrimary">
              Submit
            </button>

            <button type="button" className="ButtonCancel">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPass;
