import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import toast from 'react-hot-toast';


const ProdURL = "https://quotech-api.finsoeasy.com"
const DevURL = "https://quotech-uat-api.bravadagroup.com.au"



const apiClient = axios.create({
  baseURL: ProdURL,
  headers: {
    'Content-Type': 'application/json',
  },
});





export const login = createAsyncThunk(
  'auth/login',
  async (credentials, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const response = await apiClient.post('/api/auth/login/', credentials);
      // const response = await axios.post(
      //   'https://quotech-uat-api.bravadagroup.com.au/api/auth/login/',
      //   credentials,
      //   config,
      // );

      localStorage.setItem('IdToken', response.data.access);
      localStorage.setItem('refreshToken', response.data.refresh);
      localStorage.setItem('UserData', JSON.stringify(response.data));

      return response.data;
    } catch (error) {
      
      toast.error(error.response.data.detail)
      return rejectWithValue(error.message);
    }
  },
);


export const RefreshToken = createAsyncThunk('auth/RefreshToken', async (credentials, { rejectWithValue }) => {
  try {
      const config = {
          headers: {
              'Content-Type': 'application/json',
          },
      }

      const response = await apiClient.post('/api/auth/refresh-token/', credentials);
      // const response = await axios.post('https://quotech-uat-api.bravadagroup.com.au/api/auth/refresh-token/', credentials, config);
      // localStorage.setItem('IdToken', response.data.idToken);
      localStorage.setItem('IdToken', response.data.access);
      localStorage.setItem('refreshToken', response.data.refresh);
      localStorage.setItem('accessTokennew', response.data.access);
      return response.data;
  } catch (error) {
      return rejectWithValue(error.message);
  }
});
const UserData = localStorage.getItem('UserData');

const initialState = {
  UserDet: [],
  Login_Success: false,
  Login_Pending: false,
  UserdataMain: UserData,
  Error: '',
  IdToken: ""
  };

  const AuthApi = createSlice({
    name: 'auth',
    initialState,
    reducers: {
      logout(state) {
        state.UserDet = [];
        state.UserdataMain = [];
        state.Login_Success = false;
        state.Login_Pending = false;
        state.Error = '';
        localStorage.removeItem('UserData');
        sessionStorage.removeItem('ListExcel');
        localStorage.removeItem('IdToken');
        localStorage.removeItem('ProfileData');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('accessTokennew');
        sessionStorage.removeItem('status');
        sessionStorage.removeItem('dateRange');
        sessionStorage.removeItem('Custom start date');
        sessionStorage.removeItem('Custom end date');
        sessionStorage.removeItem('projectManager');
        sessionStorage.removeItem('searchTerm');
        sessionStorage.removeItem('currentPage');
        sessionStorage.removeItem('tags');
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(login.fulfilled, (state, action) => {
          state.UserDet = action.payload;
          state.Login_Success = true;
          state.Login_Pending = false;
        })
        .addCase(login.rejected, (state, action) => {
          state.Login_Success = false;
          state.Login_Pending = false;
          state.Error = action.payload;
        })
        .addCase(login.pending, (state) => {
          state.Login_Pending = true;
          state.Login_Success = false;
        });
    },
  });
  
  // Export the reducer
  export const { logout } = AuthApi.actions;
export default AuthApi.reducer;
